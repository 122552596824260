<template>
  <section>
        <section class="navBack">
      <v-container class="container-custom">
        <v-row>
          <v-col md="12" class="topNav">
            <ul class="navList">
              <li>Home</li>
              <li><v-icon> mdi-chevron-right </v-icon> My Vehicles</li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="3">
          <Navigation />
        </v-col>
        <v-col md="9">
          <div class="titleAction">
            <h1 class="systemTitle">My Vehicles</h1>
          
          </div>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr class="greenBack">
                  <th class="text-left">Image</th>
                  <th class="text-left">Name</th>
                  <th class="text-left">Type</th>
                  <th class="text-left">Price</th>
                  <th class="text-left">Quantity</th>
                  <th class="text-left">Action</th>
                </tr>
              </thead>
              <tbody v-if="!loading">
                <tr
                  v-for="(data, index) in products"
                  :key="index + 'product'"
                  class="rowBox"
                >
                  <td width="120">
                    <img
                      v-if="data.images.length > 0"
                      :src="data.images[0].image"
                      alt=""
                      width="100"
                    />
                  </td>
                  <td>{{ data.productName }}</td>
                  <td>
                    <strong>Rs. {{ data.price }}</strong>
                  </td>
                  <td class="text-capitalize">{{ data.productType }}</td>
                  <td>
                    <strong>{{ data.quantity }}</strong>
                  </td>
                  <td width="10">
                    <div class="d-flex">
                      <router-link :to="`/sellyourcar/${data._id}`"
                        ><v-icon> mdi-square-edit-outline </v-icon></router-link
                      >
                      <v-icon
                        class="ml-3"
                        v-if="!loadingDelete || id != data._id"
                        @click="removeProduct(data._id)"
                      >
                        mdi-delete
                      </v-icon>
                      <v-progress-circular
                        class="ml-3"
                        indeterminate
                        v-if="loadingDelete && id == data._id"
                        size="20"
                        width="2"
                      ></v-progress-circular>
                    </div>
                  </td>
                </tr>
                <tr v-if="pagination.total == 0">
                  <td>No Vehicle Added</td>
                </tr>
              </tbody>
              <tbody v-if="loading">
                <tr>
                  <td>
                    Loading
                    <v-progress-circular
                      indeterminate
                      size="20"
                      width="2"
                      class="ml-2"
                    ></v-progress-circular>
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="!loading">
                <tr>
                  <td colspan="6">
                    <ul class="pagination">
                      <li v-if="pagination.previous">
                        <v-icon @click="prev(pagination.previous)">
                          mdi-chevron-left
                        </v-icon>
                      </li>
                      <li>
                        <span v-if="pagination.previous"
                          >{{
                            pagination.previous + 1 > 1
                              ? pagination.previous + 1 * limit
                              : 1
                          }}
                          -</span
                        >
                        <span v-if="pagination.next"
                          >{{
                            pagination.next - 1 > 1
                              ? pagination.next - 1 * limit
                              : 1
                          }}
                          -</span
                        >
                        {{ pagination.total }} Items
                      </li>
                      <li v-if="pagination.next">
                        <v-icon @click="next(pagination.next)">
                          mdi-chevron-right
                        </v-icon>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table></v-col
        >
      </v-row>
    </v-container>
  </section>
</template>

<script>
import Navigation from "@/components/user/Navigation";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "InsuranceCompany",
  data: () => ({
    products: [],
    loading: true,
    loadingDelete: false,
    id: undefined,
    pagination: undefined,
    limit: 10,
    page: 1,
  }),
  computed: {
    ...mapGetters(["allProducts"]),
  },
  components:{
    Navigation
  },
  methods: {
    ...mapActions(["getProducts", "deleteProduct"]),
    async removeProduct(id) {
      this.loadingDelete = true;
      this.id = id;
      await this.deleteProduct(id);
      this.loadingDelete = false;
      this.products = this.allProducts.results;
      this.pagination = this.allProducts.pagination;
    },
    async next(data) {
      this.loading = true;
      await this.getProducts({ limit: this.limit, page: data });
      this.products = this.allProducts.results;
      this.pagination = this.allProducts.pagination;
      this.loading = false;
    },
    async prev(data) {
      this.loading = true;
      await this.getProducts({ limit: this.limit, page: data });
      this.products = this.allProducts.results;
      this.pagination = this.allProducts.pagination;
      this.loading = false;
    },
  },
  async created() {
    await this.getProducts({ limit: this.limit, page: this.page });
    this.products = this.allProducts.results;
    this.pagination = this.allProducts.pagination;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.pagination {
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .v-icon {
    font-size: 18px;
    height: 30px;
    width: 30px;
    display: flex;
  }
}
.titleAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  a {
    text-decoration: none;
  }
}
.rowBox {
  td {
    padding: 10px !important;
  }
}
.v-btn {
  color: #fff;
}
.navList {
  padding-left: 0;
  list-style: none;
  display: flex;
  font-size: 14px;
  li {
    .v-icon {
      font-size: 18px;
      margin-left: 5px;
    }
    // margin-right: 15px;
  }
}
</style>